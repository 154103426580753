.containerStep1 .button-container {
  display: flex;
  gap: 10px;
}

.containerStep1 .button-container .selected {
  background-color: #0E1F41;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #FAFAFA;
  border-radius: 10px;
  padding: 15px 8px;
  border: none;
  cursor: pointer;
}

.containerStep1 .button-container .notSelected {
  background-color: #C5D4DF;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #0E1F41;
  border-radius: 10px;
  padding: 15px 8px;
  border: none;
  cursor: pointer;
}

.containerStep1 .containerCompliment {
  margin: 10px 0px;
}

.containerStep1 .containerCompliment .title {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
  margin: 10px 0px;
}

.containerStep1 .checklist button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.containerStep1 .checklist .addAnother {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #66CDAA;
  margin-top: 10px;
}

.containerStep1 .checklist .containerCheklist {
  height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
}