.containerGeneralReports {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerGeneralReportsScroll {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 68vh;
  overflow: auto;
  margin-right: -5px;
  padding-right: 20px;
}

.containerGeneralReportsScroll::-webkit-scrollbar {
  width: 16px;
  background-color: #C5D4DF;
  border-radius: 5px;
}

.containerGeneralReportsScroll::-webkit-scrollbar-thumb {
  background-color: #0E1F41;
  border-radius: 5px;
}

.containerGeneralReportsScroll::-webkit-scrollbar-thumb:hover {
  background-color: #2C3E50;
}

.containerCardsTable {
  display: flex;
  gap: 20px;
}

.containerFinanceReports {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.containerRentation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2.5px;
  background: #FAFAFA;
  box-shadow: 0px 3px 10px 0px rgba(125, 163, 180, 0.50);
}

.containerRentation .title {
  color: #0E1F41;
  font-size: 64px;
  font-family: Roboto;
  font-weight: 500;
  margin-right: 10px;
}

.containerRentation .subtitle {
  color: #0E1F41;
  text-align: center;
  font-size: 24px;
  font-family: Roboto;
  font-weight: 700;
  margin-left: 10px;
}

.containerBestsReports {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.containerFilterReports {
  display: flex;
  gap: 40px;
}

.containerFilterReports .timeContainerButtons {
  display: flex;
  flex-wrap: wrap;
  /* width: 500px; */
}

.textCompare {
  position: absolute;
  top: -35px;
  left: 0px;
  background-color: #e5ebf2;
  z-index: 1;
  color: #0E1F41;
  font-size: 24px;
  font-family: Roboto;
  font-weight: 700;
  letter-spacing: 1.32px;
}

@media only screen and (max-width: 775px) { 
  .containerRentation .title {
    color: #0E1F41;
    font-size: 44px;
    font-family: Roboto;
    font-weight: 500;
    margin-right: 10px;
  }
  
  .containerRentation .subtitle {
    color: #0E1F41;
    text-align: center;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 700;
    margin-left: 10px;
  }
 .containerGeneralReportsScroll {
  display: flex;
  flex-direction: column;
  height: 100%;
 }

 .timeContainerButtons {
  display: flex;
  justify-content: center;
 }
} 
