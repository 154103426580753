@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.containerDeleteTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerDeleteTemplate .title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 144%;
  letter-spacing: -0.04em;
  color: #0E1F41;
  margin-bottom: 20px;
}

.containerDeleteTemplate .subtitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 144%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #0E1F41;
  margin-top: 20px;
}

.containerDeleteTemplate .line {
  border: 1px solid #BCC1E0;
  margin: 20px 0;
  width: 100%;
}

.containerButtonDeleteTemplate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerButtonDeleteTemplate button {
  width: 162px;
  height: 41px;
  border: 1px solid #0E1F41;
  border-radius: 30px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 775px) {
  .containerButtonDeleteTemplate button {
    width: 100px;
    height: 41px;
    border: 1px solid #0E1F41;
    border-radius: 30px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    cursor: pointer;
  }
}