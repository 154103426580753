@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');

.logoSett {
  position: absolute;
  top: 100px;
  left: 80px;
}

.containerForgot {
  flex: 1;
  display: flex;
  width: 100vw;
  height: 100vh;
}

.containerForgot .containerLeft {
  background-color: #1F3E7C;
  flex: 1.5;
  background-image: url('../../Assets/223516835_Mesa\ de\ trabajo\ 1\ 1.svg'), url('../../Assets/222.svg');
  background-size: contain, contain;
  background-repeat: no-repeat;
  background-position: center;
}

.containerForgot .containerRigt {
  background-color: #D5E7F4;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerForgot .containerRigt .containerINputLogin {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  width: 25rem;
  height: 20rem;
  padding: 15px 30px;
  align-items: center;
  justify-content: space-between;
}

.containerForgot .containerRigt .title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 45px;
  text-align: center;
  color: #000000;
  margin-top: 50px;
}

.containerForgot .containerRigt .subTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 33px;
  color: #000000;
}

.containerForgot .containerRigt .inputLabelRegis {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #4285F4;
  border-radius: 5px;
  height: 35px;
}

.containerForgot .rememberMe {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000;

}

.containerForgot .titleForgot {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #4285F4;
  cursor: pointer;
}

.containerForgot .buttonSigin {
  width: 90%;
  background: #0089ED;
  box-shadow: 0px 4px 19px rgba(119, 147, 65, 0.3);
  border-radius: 10px;
  height: 40px;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  color: #FFFFFF;
  cursor: pointer;
  margin-bottom: 40px;
}