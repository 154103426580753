.containerModalScree .buttonType1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: right;
  color: #F1FCFF;
  background-color: #66CDAA;
  border-radius: 5px;
  padding: 8px 10px;
  cursor: pointer;
}

.containerModalScree .buttonType2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #0E1F41;
  border: 1px solid #0E1F41;
  border-radius: 5px;
  padding: 8px 10px;
  cursor: pointer;
  min-width: 128px;
  min-height: 40px;
  text-align: center;
}

.containerModalScree .textCustonButton {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #0E1F41;
}

.containerModalcontainer {
  background: #FAFAFA;
  border-radius: 2.5px;
  width: 720px;
  padding: 20px;
}

.containerModalcontainer .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
}

.containerModalcontainer .line {
  opacity: 0.5;
  border: 2px solid #0E1F41;
  transform: rotate(-0.18deg);
  margin: 20px 0;
}

@media only screen and (max-width: 775px) {
  .containerModalScree {
    width: 80%;
  }

  .containerModalScree .buttonType1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: #F1FCFF;
    background-color: #66CDAA;
    border-radius: 2px;
    padding: 4px 5px;
    cursor: pointer;
  }

  .containerModalScree .buttonType2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #0E1F41;
    border: 1px solid #0E1F41;
    border-radius: 2px;
    padding: 4px 6px;
    cursor: pointer;
    min-width: 50px;
    min-height: 40px;
    text-align: center;
  }

  .containerModalScree .textCustonButton {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #0E1F41;
  }

  .containerModalcontainer {
    background: #FAFAFA;
    border-radius: 1.5px;
    width: 80%;
    padding: 5px;
  }

  .containerModalcontainer .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0E1F41;
  }

  .containerModalcontainer .line {
    opacity: 0.5;
    border: 2px solid #0E1F41;
    transform: rotate(-0.18deg);
    margin: 6px 0;
  }
}