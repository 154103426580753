.containerCardPayments {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.containerCardPayments .titleStatusPayments {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
  margin-left: 45px;
}

.containerCardCheckbox {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.updateALlSTatus {
  display: flex;
  justify-content: flex-end;
}

.updateALlSTatus button {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #0E1F41;
  color: #ECF7FF;
  padding: 10px;
  cursor: pointer;
  border: none;
}

.scrolFilter {

}

.scrolFilter {
  height: 85vh;
  overflow: auto;
}

.scrolFilter::-webkit-scrollbar {
  width: 8px;
  background-color: #C5D4DF;
  border-radius: 5px;
}

.scrolFilter::-webkit-scrollbar-thumb {
  background-color: #0E1F41;
  border-radius: 5px;
}

.scrolFilter::-webkit-scrollbar-thumb:hover {
  background-color: #2C3E50;
}


@media only screen and (max-width: 775px) {
  .containerCardPayments {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  body .containerPamentsCardsAll {
    display: flex;
    flex-direction: column !important;
    overflow: auto;
  }

  body .containerPamentsCardsAll .scrolFilter {
    height: 500px !important;
    /* display: none; */
  }
  
  .containerCardPayments .titleStatusPayments {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #0E1F41;
    margin-left: 20px;
  }
  
  .containerCardCheckbox {
    display: flex;
    align-items: center;
    gap: 5px;
    overflow: auto;
  }

  body .containerCardCheckbox .containerButtonPayments {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .updateALlSTatus {
    display: flex;
    justify-content: flex-end;
  }
  
  .updateALlSTatus button {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #0E1F41;
    color: #ECF7FF;
    padding: 10px;
    cursor: pointer;
    border: none;
  }
  
  .scrolFilter {
  
  }
  
  .scrolFilter {
    height: 85vh;
    overflow: auto;
  }
  
  .scrolFilter::-webkit-scrollbar {
    width: 8px;
    background-color: #C5D4DF;
    border-radius: 5px;
  }
  
  .scrolFilter::-webkit-scrollbar-thumb {
    background-color: #0E1F41;
    border-radius: 5px;
  }
  
  .scrolFilter::-webkit-scrollbar-thumb:hover {
    background-color: #2C3E50;
  }

  .filterMobilePayments {
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    padding: 10px;
    /* width: 100%; */
  }
}