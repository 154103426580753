.graphText,
.buttonGraph {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  letter-spacing: -0.035em;
  color: #0E1F41;
}

.buttonGraph {
 position: absolute;
 right: 0;
}