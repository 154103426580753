.containerCardsNews .contentTextNews {
  display: flex;
  flex-direction: column;
  gap: 5x;
  margin: 10px 0;
  width: 100%;
}

.containerCardsNews .title {
  font-family: 'Roboto';
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
}

.containerCardsNews .subtitle {
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
  margin-bottom: 8px;
}

.containerCardsNews .text {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
  margin-bottom: 8px;
  margin-right: 10px;
}

.containerCardsNews .headerCardNews {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.containerCardsNews .headerCardNews .containerButtonNewsCard {
  display: flex;
  align-items: center;
  gap: 10px;
}


.containerCardsNews .headerCardNews .containerButtonNewsCard button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.containerCardsNews .headerCardNews .containerButtonNewsCard .selected svg{
  fill: #0E1F41;
}

.textArea::placeholder {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #0E1F41;
  margin-top: 10px;
  opacity: 0.5;
}

.textArea {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;
}

.containerImageNews {
  display: flex;
  position: relative;
  /* width: 163px;
  height: 96px; */
}

.containerImageNews .titleInput {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}