.containerJobs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.containerJobs .cardJobs {
  display: flex;
  flex-direction: column;
}

.containerJobs .cardJobs .headerJobs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  background-color: #0e1f41;
  padding: 10px;
  color: aliceblue;
}

.containerJobs .cardJobs .headerTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  font-weight: 700;
  /* font-size: 15px; */
}

.containerJobs .cardJobs .headerTitle div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.containerJobs .cardJobs .color {
  background-color: #c5d4df;
}

.containerJobs .cardJobs .contentJobs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.containerJobs .cardJobs .contentJobs div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
}

.containerJobs .buttonAdjustment {
  background-color: #0e1f41;
  padding: 10px;
  color: aliceblue;
  cursor: pointer;
}

.containerJobs .butonExport {
  width: 200px;
  align-self: flex-end;
  background-color: rgb(3, 100, 68);
  color: #c5d4df;
  padding: 10px;
  border: none;
  cursor: pointer;
  height: 50px;
}