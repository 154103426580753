.download {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7.54717px 22.6415px;
  gap: 15.09px;
  width: 250.95px;
  height: 41.51px;
  background: #0E1F41;
  border: 3.77359px solid #0E1F41;
  border-radius: 9.43396px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 22.6415px;
  line-height: 27px;
  text-align: right;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  cursor: pointer;
}

.print {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7.54717px 22.6415px;
  gap: 15.09px;
  width: 250.94px;
  height: 41.51px;
  border: 3.77359px solid #0E1F41;
  border-radius: 9.43396px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 22.6415px;
  line-height: 27px;
  text-align: right;
  color: #0E1F41;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  background-color: transparent;
}

.titleDOcument {
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  color: #0E1F41;
}

@media print {
  .containerPhotoMOdal .buttons {
    display: none !important;
  }

  .containerPhotoMOdal img {
    display: block !important;
    margin: 0 auto !important;
    max-width: 100% !important;
    height: auto !important;
    filter: none !important;
    transition: none !important;
  }

  .non-printable-element {
    display: none !important;
  }
}
