.city-list-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
}

.city-list {
  display: flex;
  flex-wrap: nowrap;
  background: #FAFAFA;
  border: 2px solid #0E1F41;
  gap: 10px;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.city-item {
  background: #0E1F41;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FAFAFA;
  display: flex;
  align-items: center;
  height: auto;
  width: auto;
  padding: 3px;
}

.show-more {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-right: 10px;
}

.show-more i {
  font-size: 18px;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h3 {
  margin: 0;
  font-size: 24px;
}

.modal-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.modal-body .city-item {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}