.containerTotal {
  display: flex;
  flex: 1;
  gap: 25px;
}

.containerTotal .cardTotal {
    flex: 1;
    /* height: 100px; */
    border-radius: 2.5px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.containerTotal .cardTotal .titleTotal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
}

.containerTotal .cardTotal .subtitleTotal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}

.cardCoupon {
  height: 100%;
  border-radius: 2.5px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 0px 20px;
  gap: 25px;
}

.cardCoupon .titleCoupom {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 42px;
  color: #0E1F41;
  margin-top: 25px;
}

.cardCoupon .titleCoupom2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
  margin-top: 25px;
}

.cardCoupon .subtitleCoupun {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.02em;

  color: #0E1F41;
}

.image {
  background: url("../../Assets/Payments Bg Animation.svg");
  background-repeat: no-repeat;
  height: 100%;
}

.titleGraph {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
}

.containerTitleGraph {
  border-bottom: 1px dashed #0E1F41;
  padding: 15px;
}

.subTitleCalendar {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  text-align: right;
  letter-spacing: 0.02em;

  color: #0E1F41;
}

.boxShadon {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
}

.titleByGraph {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #0E1F41;
  margin-bottom: 0px;
  padding: 0;
}

@media only screen and (max-width: 775px) {
  .containerTotal .cardTotal .titleTotal {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #0E1F41;
  }
  
  .containerTotal .cardTotal .subtitleTotal {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  
  
  .cardCoupon .titleCoupom {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    color: #0E1F41;
    margin-top: 15px;
  }
  
  .cardCoupon .titleCoupom2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #0E1F41;
    margin-top: 15px;
  }
  
  .cardCoupon .subtitleCoupun {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
  
    color: #0E1F41;
  }
  
  .titleGraph {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #0E1F41;
  }
  
  .containerTitleGraph {
    border-bottom: 1px dashed #0E1F41;
    padding: 10px;
  }
  
  .subTitleCalendar {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    text-align: right;
    letter-spacing: 0.02em;
  
    color: #0E1F41;
  }
  
  
  .titleByGraph {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #0E1F41;
    margin-bottom: 0px;
    padding: 0;
  }
  
}
