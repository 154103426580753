.containerModalDetails {
  width: 700px;
  height: 85vh;
  background-color: #E5EBF2;
  border-radius: 2.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  overflow: auto;
}

.containerModalData {
  width: 700px;
  height: 85vh;
  background-color: #e5ebf234;
  border-radius: 2.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  overflow: auto;
}

.containerModalData .containerTexts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.containerModalData .containerInfo .text {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.buttonCardDetails {
  background: #66CDAA;
  border-radius: 5px;
  width: 79px;
  height: 41px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #FAFAFA;
  cursor: pointer;
  border: none;
}

.containerModalDetails::-webkit-scrollbar {
  display: none;
}

.containerModalDetails .containerTexts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.containerModalDetails .buttonAddPayments {
  width: 100%;
  height: 44px;
  background: #66CDAA;
  border-radius: 4px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #F1FCFF;
  cursor: pointer;
}

.containerModalDetails .titleAll {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
  margin: 10px 0;
}

.containerModalDetails .containerHeader {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 0.8px solid #cdd1d7;
  padding: 15px 0;
  margin-bottom: 15px;
}

.containerModalDetails .containerHeader .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
}

.containerModalDetails .containerHeader .text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #0E1F41;

}

.containerModalDetails .containerInfo {
  display: flex;
  gap: 20px;
  border-bottom: 0.8px solid #cdd1d7;
  padding: 15px 0;
  margin-bottom: 15px;
}

.containerModalDetails .containerInfo .text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.containerModalDetails .containerReview {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerModalDetails .containerReview .containerHeaderReview {
  display: flex;
  align-items: center;
  gap: 5px;
}

.containerModalDetails .containerReview .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
}

.containerModalDetails .containerReview .text {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
  color: #0E1F41;
}

.containerModalDetails .containerCuston {
  display: flex;
  gap: 20px;
}

.containerModalDetails .containerCuston .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
  margin: 10px 0;
}

.containerModalDetails .text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0E1F41;
}

.containerModalDetails .containerCuston .containerCheckList {
  display: flex;
  flex-direction: column;
}

.houverLinkHost:hover {
  border-bottom: 1px solid #0e1f41;
  cursor: pointer;
}

.containerHeaderCheck {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.containerHeaderCheck button {
  height: 40px;
  width: 100px;
  border: none;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  background-color: #0E1F41;
  margin: 10px 0;
  color: #cdd1d7;
}

@media only screen and (max-width: 775px) {
  body .containerModalDetails {
    width: 80% !important;
    height: 80% !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

   body .containerModalDetails .titleAll {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    color: #0E1F41;
    margin: 10px 0;
  }

   body .containerModalDetails .containerHeader .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #0E1F41;
  }
  
   body .containerModalDetails .containerHeader .text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: right;
    color: #0E1F41;
  
  }
  
   body .containerModalDetails .containerInfo .text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #0E1F41;
  }
  
  
   body .containerModalDetails .containerReview .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #0E1F41;
  }
  
   body .containerModalDetails .containerReview .text {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: justify;
    color: #0E1F41;
  }
  
   body .containerModalDetails .containerCuston .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #0E1F41;
    margin: 10px 0;
  }
  
   body .containerModalDetails .text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #0E1F41;
  }
  body .titleSapnMObile {
    font-size: 15px !important;
  }
}
