.containerIcalendar {
  display: flex;
  gap: 20px;
}

.containerIcalendar .containerCard {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  background-color: transparent;
  width: 100%;
  padding: 0;
  overflow: auto;
  /* height: 85vh; */
}


.containerIcalendar .containerCard::-webkit-scrollbar {
  display: none;
}

.containerIcalendar .containerCardAccounts {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  width: 476px;
  height: 88px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.containerIcalendar .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.055em;
  color: #0E1F41;
}

.containerCard .subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.containerCard .subtitlePerfil2 {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #0E1F41;
}
.titleTop {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  display: flex;
  align-items: flex-end;
  color: #0E1F41;
}