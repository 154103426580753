.containerStep1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.containerStep1 .headerStep {
  margin-top: 20px;
}

.containerStep1 .headerStep .title {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #66CDAA;
}

.containerStep1 .headerStep .subtitle {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: justified;
  color: #0E1F41;
}

.containerStep1 .containerBuntoSelectPhoto {
  width: 100%;
  display: flex;
  justify-content: center;
}

.containerStep1 .containerBuntoSelectPhoto button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.switch2 {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 35px;
}

.switch2 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch2 .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #C5D4DF;
  box-shadow: inset 0px 2.66667px 3.55556px 1.33333px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0.888889px 0.444444px 2.66667px rgba(0, 0, 0, 0.25));
  border-radius: 22.2222px;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
}

.switch2 .slider:before {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  background: linear-gradient(180deg, #FFFFFF 0%, #E8EAEA 100%);
  filter: drop-shadow(0.888889px 0.444444px 2.66667px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  transition: transform 0.2s;
}

.switch2 input:checked+.slider {
  background: #0E1F41;
  box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);
  border-radius: 22.2222px;
}

.switch2 input:checked+.slider:before {
  transform: translateX(35px);
}

.conatinerSLider {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 10px;
}

.conatinerSLider .textSlider {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
}

.containerStep1 .title {
  color: #0E1F41;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
}

.containerRooms {
  display: flex;
  align-items: center;
  gap: 35px;
}

.containerRooms .bedBath {
  display: flex;
  align-items: center;
  gap: 5px;
}

.containerRooms .bedBath .text {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
}

.containerStep1 .inputText {
  border-radius: 8px;
  border: 1px solid #C5D4DF;
  height: 20px;
}

.containerStep1 .titleINputModalStep {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
  margin-bottom: 5px;
}

.containerStep1 .cnotainerAddresStep {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
}

@media only screen and (max-width: 775px) {
  .switch2 {
    position: relative;
    display: inline-block;
    width: 50px !important;
    height: 25px !important;
  }
  
  .switch2 input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .switch2 .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #C5D4DF;
    box-shadow: inset 0px 2.66667px 3.55556px 1.33333px rgba(0, 0, 0, 0.1);
    filter: drop-shadow(0.888889px 0.444444px 2.66667px rgba(0, 0, 0, 0.25));
    border-radius: 22.2222px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
  }
  
  .switch2 .slider:before {
    position: absolute;
    content: '';
    height: 20px !important;
    width: 20px !important;
    background: linear-gradient(180deg, #FFFFFF 0%, #E8EAEA 100%);
    filter: drop-shadow(0.888889px 0.444444px 2.66667px rgba(0, 0, 0, 0.25));
    border-radius: 50%;
    transition: transform 0.2s;
  }
  
  .switch2 input:checked+.slider {
    background: #0E1F41;
    box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);
    border-radius: 22.2222px;
  }
  
  .switch2 input:checked+.slider:before {
    transform: translateX(35px);
  }
  
  .conatinerSLider {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 10px;
  }
  
  .conatinerSLider .textSlider {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #0E1F41;
  }

  .mobileROoms {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  .mobileROoms .itensMObi {
    display: flex !important;
    width: 70%;
    justify-content: space-between;
  }

  .mobileROoms .imgs {
    width: 32px; /* Defina a largura do SVG */
    height: 32px
  }
}