.containerModalAdminControl {
  width: 500px;
  /* height: 586px; */
  background: #E5EBF2;
  border-radius: 2.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
}

.titleModalAdmin {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
  margin-top: 20px;
}

.lineAdminControl {
  opacity: 0.5;
  border: 2px solid #0E1F41;
  margin-top: 24px;
  margin-bottom: 14px;
}

.openModalAdminCOntrol {
  /* background: #66CDAA; */
  border-radius: 5px;
  /* padding: 3px; */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #FAFAFA;
  background-color: transparent;
  cursor: pointer;
  border: none;
}

.containerButtonModalAdmin {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
}

.containerButtonModalAdmin .create, .containerButtonModalAdmin .cancel {
  width: 128px;
  height: 40px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #F1FCFF;
}

.containerButtonModalAdmin .cancel {
  background-color: #E64242;
}

.containerButtonModalAdmin .create {
  background-color: #66CDAA;
}

@media only screen and (max-width: 775px) {
  body .containerModalAdminControl {
    width: 80%;
    /* height: 586px; */
    background: #E5EBF2;
    border-radius: 2.5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
  }
  
  body .titleModalAdmin {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #0E1F41;
    margin-top: 10px;
  }
  
  body .lineAdminControl {
    opacity: 0.5;
    border: 2px solid #0E1F41;
    margin-top: 14px;
    margin-bottom: 10px;
  }
  
  body .openModalAdminCOntrol {
    /* background: #66CDAA; */
    border-radius: 5px;
    /* padding: 3px; */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #FAFAFA;
    background-color: transparent;
    cursor: pointer;
    border: none;
  }
  
  body .containerButtonModalAdmin {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
  }
  
  body .containerButtonModalAdmin .create, .containerButtonModalAdmin .cancel {
    width: 60;
    height: 30px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 28px;
    color: #F1FCFF;
  }
}