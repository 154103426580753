.containerNewService {
  display: flex;
  flex-direction: column;
}

.containerNewService .containerHeaderNewService {
  margin: 15px 0;
}

.containerNewService .containerHeaderNewService button {
  background-color: transparent;
  border: none;
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
}

.containerNewService .containerInputsNewService {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  width: 98%;
  height: 75vh;
  padding: 15px;
}

.containerInputsNewService .containerTop {
  display: flex;
  align-items: center;
  gap: 40px;
}

.containerInputsNewService .containerTop .title,
.inputsTOps {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
  margin-bottom: 5px;
}

.containerInputsNewService .containerTop .buttonTYpe {
  display: flex;
  gap: 10px;
  border: none;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.containerInputsNewService .containerTop .buttonSelectTYpe {
  width: 16px;
  height: 16px;
  background: #848d9e;
  border-radius: 50%;
}

.containerInputsNewService .containerTop .selecteStyle {
  background: #0E1F41;
}

.containerInputsNewService .containerTop .selectedText {
  opacity: 1 !important;
}

.containerInputsNewService .containerTop .buttonTYpe .titleTYpe {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
  opacity: 0.5;
}

.containerInputsNewService .containerCenter {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
}

.containerInputsNewService .containerCenter .containerHeaderTable {
  display: flex;
}

.containerInputsNewService .containerCenter .containerHeaderTable div {
  flex: 1;
  background: #C5E0F3;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0E1F41;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #0E1F41;
  margin-bottom: 10px;
}

.containerInputsNewService .containerCenter .containerLineTable {
  display: flex;
  margin-bottom: 10px;
}


.containerInputsNewService .containerCenter .containerLineTable div {
  flex: 1;
  background: #FAFAFA;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0E1F41;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #000000;
}

.containerInputsNewService .containerCenter .containerLineErros {
  display: flex;
}

.containerInputsNewService .containerCenter .containerLineErros .textErros {
  flex: 1;
  background: #FAFAFA;
  height: 24px;
  display: flex;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
}

.containerInputsNewService .containerCenter .buttonNewLine {
  align-self: flex-end;
  margin-top: 10px;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #0E1F41;
}


.containerNewService .buttonSaveServide {
  background: #66CDAA;
  border-radius: 5px;
  width: 180px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: right;
  color: #F1FCFF;
  cursor: pointer;
  border: none;
  position: absolute;
  bottom: 200px;
  right: 50px;
}

.inputTextPrice {
  width: 100%;
  height: 80%;
  border: none;
  outline: none;
}

.containerLineTable label,
.containerLineTable input {
  position: relative;
  /* width: 78px; */
}

.containerLineTable label::after {
  content: '' attr(unit);
  position: absolute;
  top: 3px;
  right: 20px;
  font-family: arial, helvetica, sans-serif;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}

.containerNewService .containerTopIputs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containerInputsNewService .containerCenter .deletedLine {
  display: none;
}

@media only screen and (max-width: 775px) {
  .containerNewService {
    display: flex;
    flex-direction: column;
  }
  
  .containerNewService .containerHeaderNewService {
    margin: 15px 0;
  }
  
  .containerNewService .containerHeaderNewService button {
    background-color: transparent;
    border: none;
    display: flex;
    gap: 10px;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #0E1F41;
  }
  
  .containerNewService .containerInputsNewService {
   display: flex;
   flex-direction: column;
   height: 100%;
  }
  
  .containerInputsNewService .containerTop {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
  }
  
  .containerInputsNewService .containerTop .title,
  .inputsTOps {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #0E1F41;
    margin-bottom: 5px;
  }
  
  .containerInputsNewService .containerTop .buttonTYpe {
    display: flex;
    gap: 10px;
    border: none;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
  
  .containerInputsNewService .containerTop .buttonSelectTYpe {
    width: 16px;
    height: 16px;
    background: #848d9e;
    border-radius: 50%;
  }
  
  .containerInputsNewService .containerTop .selecteStyle {
    background: #0E1F41;
  }
  
  .containerInputsNewService .containerTop .selectedText {
    opacity: 1 !important;
  }
  
  .containerInputsNewService .containerTop .buttonTYpe .titleTYpe {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #0E1F41;
    opacity: 0.5;
  }
  
  .containerInputsNewService .containerCenter {
    /* margin-top: 15px; */
    display: flex;
    flex-direction: column;
  }
  
  .containerInputsNewService .containerCenter .containerHeaderTable {
    display: flex;
    flex-direction: column;
  }
  
  .containerInputsNewService .containerCenter .containerHeaderTable div {
    flex: 1;
    background: #C5E0F3;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #0E1F41;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #0E1F41;
    margin-bottom: 10px;
    flex-direction: column;
  }
  
  .containerInputsNewService .containerCenter .containerLineTable {
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
  }
  
  
  .containerInputsNewService .containerCenter .containerLineTable div {
    flex: 1;
    background: #FAFAFA;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #0E1F41;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #000000;
  }
  
  .containerInputsNewService .containerCenter .containerLineErros {
    display: flex;
  }
  
  .containerInputsNewService .containerCenter .containerLineErros .textErros {
    flex: 1;
    background: #FAFAFA;
    height: 24px;
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
  }
  
  .containerInputsNewService .containerCenter .buttonNewLine {
    align-self: flex-end;
    margin-top: 10px;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #0E1F41;
  }
  
  
  .containerNewService .buttonSaveServide {
    background: #66CDAA;
    border-radius: 5px;
    width: 180px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: right;
    color: #F1FCFF;
    cursor: pointer;
    border: none;
    position:static;
    bottom: none;
    right: none;
  }
  
  .inputTextPrice {
    width: 100%;
    height: 80%;
    border: none;
    outline: none;
  }
  
  .containerLineTable label,
  .containerLineTable input {
    position: relative;
    /* width: 78px; */
  }
  
  .containerLineTable label::after {
    content: '' attr(unit);
    position: absolute;
    top: 3px;
    right: 20px;
    font-family: arial, helvetica, sans-serif;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
  }
  
  .containerNewService .containerTopIputs {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .containerInputsNewService .containerCenter .deletedLine {
    display: none;
  }

  .inputsTOps {
    flex-direction: column;
  }
}