.containerCardProperty {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-shadow: 0px 3px 10px 0px #7DA3B480;
}

.containerCardProperty .textitle {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
  margin-bottom: 5px;
}

.containerCardProperty .textCardroperties {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
  margin-bottom: 5px;
}

.containerCardProperty .containerHeaderCardProperty {
  display: flex;
  justify-content: space-between;
}

.containerCardProperty .containerHeaderCardProperty .restoreButton {
  width: 79px;
  height: 30px;
  border-radius: 5px;
  background-color: #66CDAA;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  cursor: pointer;
  color: #FAFAFA;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerCardProperty .containerHeaderCardProperty .scheduleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  width: 105px;
  height: 30px;
  border-radius: 5px;
  background-color: #0E1F41;
  color: #ECF7FF;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

body .urlMobile {
  max-width: 80% !important;
  overflow: visible; /* Permite que o texto transborde sem ocultar */
  white-space: normal; /* Permite que o texto quebre em várias linhas */
  flex: 1; /* Mantém a flexibilidade dentro do layout */
  word-break: break-word; /* Garante que palavras longas sejam quebradas se necessário */
}



.cntainerBUttonDetailsCLients {
  flex-direction: column;
  flex: 1;
}

@media only screen and (max-width: 775px) {
  .containerCardProperty {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    box-shadow: 0px 3px 10px 0px #7DA3B480;
  }

  .containerCardProperty .textitle {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #0E1F41;
    margin-bottom: 5px;
  }

  .containerCardProperty .textCardroperties {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    font-family: Roboto;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #0E1F41;
    margin-bottom: 5px;
  }

  .containerCardProperty .containerHeaderCardProperty {
    display: flex;
    justify-content: space-between;
  }

  .containerCardProperty .containerHeaderCardProperty .restoreButton {
    width: 70px;
    height: 30px;
    border-radius: 5px;
    background-color: #66CDAA;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    cursor: pointer;
    color: #FAFAFA;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .containerCardProperty .containerHeaderCardProperty .scheduleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    width: 90px;
    height: 30px;
    border-radius: 5px;
    background-color: #0E1F41;
    color: #ECF7FF;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
  }

  body .urlMobile {
    flex: none;
  }

  .propertiesItensCOntainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .propertiesItens {
    flex-direction: row !important;
  }

  .cntainerBUttonDetailsCLients {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .buttonModalMObile {
    width: 100% !important;
  }
}