.containerOverview {
  display: flex;
  gap: 20px;
}

.containerFilter {
  display: flex;
  flex-direction: column;
  background-color: #FAFAFA;
  width: 300px;
  min-height: 1vh;
  padding: 20px;
  position: absolute;
  bottom: 0;
  top: 85px;
  /* left: 345px; */
}

.containerBox {
  width: 400px;
  min-height: 1vh;
  padding: 20px;
}

.containerOverview .containerCard {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  background-color: transparent;
  width: 100%;
  padding: 0;
  overflow: auto;
  /* height: 85vh; */
}

.containerPagination {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containerOver {
  /* height: 80vh; */
}

.containerOverview .containerCard::-webkit-scrollbar {
  display:none;
}

.containerOverview .containerCard .cardInfos {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  width: 340px;
  min-height: 150px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.cardId {
  position: absolute;
  right: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  letter-spacing: 0.055em;
  color: #0E1F41;
}

.textCardCleaner {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.055em;
  color: #0E1F41;
}

.userPhoto {
  background: #FFFFFF;
  border: 4px solid #66CDAA;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.textDate {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.textCardOver {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.titleFilter {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
  margin-top: 20px;
}

.subTitleFilter {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}
.textTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #0E1F41;
  border-bottom: 2px solid #0E1F41;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.buttonFilter {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #F1FCFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  background: #E64242;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: none;
  width: 126px;
  height: 35px;
  border-radius: 5px;
  align-self: center;
  cursor: pointer;
}

body .statusMobile {
  font-size: 20px;
}

@media only screen and (max-width: 775px) {
  body .containerOverview {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow: hidden;
  }
  
  body .containerFilter {
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    width: 100%;
    position:static;
    margin-top: 0;
    
  }
  
  body .containerOverview .containerCard .cardInfos {
    background: #FAFAFA;
    box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
    border-radius: 2.5px;
    width:90%;
    min-height:50px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  
  body .cardId {
    position: absolute;
    right: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 0.055em;
    color: #0E1F41;
  }
  
  body .textCardCleaner {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.055em;
    color: #0E1F41;
  }
  
  body .userPhoto {
    background: #FFFFFF;
    border: 4px solid #66CDAA;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  body .textDate {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: #0E1F41;
  }
  
  body .textCardOver {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #0E1F41;
  }
  
  body .titleFilter {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    color: #0E1F41;
    margin-top: 20px;
  }
  
  body .subTitleFilter {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #0E1F41;
  }
  body .textTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    color: #0E1F41;
    border-bottom: 2px solid #0E1F41;
    padding-bottom: 8px;
    margin-bottom: 10px;
  }

  body .buttonCardDetails {
    font-size: 15px;
    width: 60px;
    height: 30px;
  }
  body .statusMobile {
    font-size: 15px;
  }

  body .inputCOntianerMObile {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  body .inputMObile {
    width: 90%;
  }
}
