.containerPaymentCard {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.containerPaymentCard .itemValue {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  flex: 1;
}

.containerPaymentCard .itemValue .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #0E1F41;
  text-align: center;
}

.containerPaymentCard .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  gap: 10px;
  padding: 10px;
}

.containerPaymentCard .card .cardItens {
  display: flex;
  align-items: center;
  gap: 12px;
}

.containerPaymentCard .card .cardItens .containerImg {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerPaymentCard .card .cardItens .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
}

.containerPaymentCard .card .cardItens .subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.containerPaymentCard .cardButton {
  border: none;
  cursor: pointer;
  background: #66CDAA;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #F1FCFF;
}

.containerPaymentCard .id {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  letter-spacing: 0.055em;
  color: #0E1F41;
}

.titleStatus {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
}

.containerPaymentCard .redColor {
  color: red;
}

@media only screen and (max-width: 775px) {
  .containerPaymentCard {
    background: #FAFAFA;
    box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
    border-radius: 2.5px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .containerPaymentCardMobile {
    background: #FAFAFA;
    box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
    border-radius: 2.5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;
  }

  .containerPaymentCardMobile .textMobile {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .containerPaymentCardMobile .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  
  .containerPaymentCard .itemValue {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    flex: 1;
  }
  
  .containerPaymentCard .itemValue .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #0E1F41;
    text-align: center;
  }
  
  .containerPaymentCard .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    gap: 10px;
    padding: 10px;
  }
  
  .containerPaymentCard .card .cardItens {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .containerPaymentCard .card .cardItens .containerImg {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .containerPaymentCard .card .cardItens .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #0E1F41;
  }
  
  .containerPaymentCard .card .cardItens .subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #0E1F41;
  }
  
  .containerPaymentCard .cardButton {
    border: none;
    cursor: pointer;
    background: #66CDAA;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #F1FCFF;
  }
  
  .containerPaymentCard .id {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.055em;
    color: #0E1F41;
  }
  
  .titleStatus {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #0E1F41;
  }
  
  .containerPaymentCard .redColor {
    color: red;
  }
}