.containerPriceTable .titleTable {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #0E1F41;
}

.containerPriceTable .subtitleTable {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #0E1F41;
}

.containerHeaderTablePrice {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.containerHeaderTablePrice .left {
  display: flex;
  gap: 20px;
  align-items: center;
}

.containerHeaderTablePrice .rigth {
  display: flex;
  gap: 10px;
  align-items: center;
}

.containerHeaderTablePrice .left .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
}

.containerHeaderTablePrice .left button {
  width: 176px;
  height: 39px;
  background: #66CDAA;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #F1FCFF;
  border: none;
  cursor: pointer;
}

.containerHeaderTablePrice .rigth .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #0E1F41;
}

.containerSelectAdmin .selectedContainer {
  background: #FAFAFA;
  border: 1px solid #0E1F41;
  border-radius: 2.5px;
  width: 160px;
  padding: 12px;
}

.containerSelectAdmin .selectedContainer div {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.containerScrollTable {
  height: 40vh;
}


@media only screen and (max-width: 775px) {
  .containerPriceTable .titleTable {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #0E1F41;
  }
  
  .containerPriceTable .subtitleTable {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #0E1F41;
  }
  
  .containerHeaderTablePrice {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
  }
  
  .containerHeaderTablePrice .left {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  
  .containerHeaderTablePrice .rigth {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .containerHeaderTablePrice .left .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #0E1F41;
  }
  
  .containerHeaderTablePrice .left button {
    width: 176px;
    height: 39px;
    background: #66CDAA;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    color: #F1FCFF;
    border: none;
    cursor: pointer;
  }
  
  .containerHeaderTablePrice .rigth .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #0E1F41;
  }
  
  
  .containerSelectAdmin .selectedContainer {
    background: #FAFAFA;
    border: 1px solid #0E1F41;
    border-radius: 2.5px;
    width: 160px;
    padding: 12px;
  }
  
  .containerSelectAdmin .selectedContainer div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    color: #0E1F41;
  }
  
  .containerScrollTable {
    height: 40vh;
  }
  
}