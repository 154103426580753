.containerEmails {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  padding: 15px 20px;
  box-sizing: border-box;
  position: relative;
}

.titleTextEmailfilter {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.containerEmails .containerHeaderEmails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerEmails .containerHeaderEmails .button {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: right;
  color: #0E1F41;
  cursor: pointer;
}


.containerEmails .titleTextEmail {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
  margin: 10px 0;
}

.containerEmails .selectedItemSendEMail {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #FAFAFA;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.containerEmails .selectedItemSendEMail span {
  background: #0E1F41;
  padding: 0 5px;
}

.containerFIlterEmails {
  display: flex;
  gap: 10px;
}

.containerFIlterEmails .selectedItemSendEMail {
  display: flex;
}

.containerFIlterEmails .selectedItemSendEMail .name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  background: #0E1F41;
  color: #FAFAFA;
  padding: 0 5px;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.containerEmails button {
  background: #66CDAA;
  border-radius: 5px;
  padding: 16px 64px;
  height: 60px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #F1FCFF;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.containerMenuHeaderEmails {
  display: flex;
  align-self: flex-end;
  gap: 50px;
  margin-bottom: 10px;
}

.containerMenuHeaderEmails .selectedCLeaner {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  text-decoration-line: underline;
  color: #0E1F41;
  cursor: pointer;
}

.containerMenuHeaderEmails .notSelectedCLeaner {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #0E1F41;
  opacity: 0.5;
  cursor: pointer;
}

@media only screen and (max-width: 775px) {
  .containerEmails {
    background: #FAFAFA;
    box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
    border-radius: 2.5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    position: relative;
  }
  
  .titleTextEmailfilter {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #0E1F41;
  }
  
  .containerEmails .containerHeaderEmails {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .containerEmails .containerHeaderEmails .button {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #0E1F41;
    cursor: pointer;
  }
  
  
  .containerEmails .titleTextEmail {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    color: #0E1F41;
    margin: 10px 0;
  }
  
  .containerEmails .selectedItemSendEMail {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FAFAFA;
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  
  .containerEmails .selectedItemSendEMail span {
    background: #0E1F41;
    padding: 0 5px;
  }
  
  .containerFIlterEmails {
    display: flex;
    gap: 10px;
  }
  
  .containerFIlterEmails .selectedItemSendEMail {
    display: flex;
  }
  
  .containerFIlterEmails .selectedItemSendEMail .name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    background: #0E1F41;
    color: #FAFAFA;
    padding: 0 5px;
    width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .containerEmails button {
    background: #66CDAA;
    border-radius: 5px;
    padding: 16px 64px;
    height: 30px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: right;
    color: #F1FCFF;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
  
  .containerMenuHeaderEmails {
    display: flex;
    align-self: flex-end;
    gap: 20px;
    margin-bottom: 10px;
  }
  
  .containerMenuHeaderEmails .selectedCLeaner {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: #0E1F41;
    cursor: pointer;
  }
  
  .containerMenuHeaderEmails .notSelectedCLeaner {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #0E1F41;
    opacity: 0.5;
    cursor: pointer;
  }
}
