@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.containerTopBar {
  background-color: #EDF1F5;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.containerRightTopBar {
  display: flex;
  align-items: center;
}

.containerRightTopBar .containerText {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  margin: 0 25px;
}

.containerRightTopBar .containerText .name {
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: 400;
  color: #0E1F41;
}

.containerRightTopBar .containerText .position {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #A9B2C2;
 }

 @media only screen and (max-width: 775px) {
  .searchTopBar {
    display: none;
  }
} 
