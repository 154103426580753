.buttonPdfLog {
  display: flex;
  gap: 5px;
  border-bottom: 1px solid #0E1F41;
  width:max-content;
  justify-content: flex-end;
}

.buttonPdfLog button {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #0E1F41;
}