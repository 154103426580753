.containerModalDetails {
  width: 700px;
  height: 85vh;
  background-color: #E5EBF2;
  border-radius: 2.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  overflow: auto;
}

.containerModalDetails::-webkit-scrollbar {
  display: none;
}

.containerModalDetails .containerTexts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.containerModalDetails .buttonAddPayments {
  width: 100%;
  height: 44px;
  background: #66CDAA;
  border-radius: 4px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #F1FCFF;
  cursor: pointer;
}

.containerModalDetails .titleAll {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
  margin: 10px 0;
}

.containerModalDetails .containerHeader {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 0.8px solid #cdd1d7;
  padding: 15px 0;
  margin-bottom: 15px;
}

.containerModalDetails .containerHeader .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
}

.containerModalDetails .containerHeader .text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #0E1F41;

}

.containerModalDetails .containerInfo {
  display: flex;
  gap: 20px;
  border-bottom: 0.8px solid #cdd1d7;
  padding: 15px 0;
  margin-bottom: 15px;
}

.containerModalDetails .containerInfo .text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.containerModalDetails .containerReview {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerModalDetails .containerReview .containerHeaderReview {
  display: flex;
  align-items: center;
  gap: 5px;
}

.containerModalDetails .containerReview .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
}

.containerModalDetails .containerReview .text {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
  color: #0E1F41;
}

.containerModalDetails .containerCuston {
  display: flex;
  gap: 20px;
}

.containerModalDetails .containerCuston .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
  margin: 10px 0;
}

.containerModalDetails .text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0E1F41;
}

.containerModalDetails .containerCuston .containerCheckList {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 775px) {
  .containerModalDetails {
    width: 90%;
  }
}