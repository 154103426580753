.containerSumary {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
}

.containerSumary .containerCards {
  display: flex;
  flex: 1;
  gap: 20px;
}

.containerSumary .containerCards .cardIssuer {
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  height: 600px;
  padding: 20px;
}

.containerSumary .containerCards .cardIssuer::-webkit-scrollbar {
  width: 8px;
  background-color: #C5D4DF;
  border-radius: 5px;
}

.containerSumary .containerCards .cardIssuer::-webkit-scrollbar-thumb {
  background-color: #0E1F41;
  border-radius: 5px;
}

.containerSumary .containerCards .cardIssuer::-webkit-scrollbar-thumb:hover {
  background-color: #2C3E50;
}

.containerSumary .containerCards .card .containerText {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin: 5px 0;
}

.containerSumary .subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.containerSumary .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
  margin-bottom: 5px;
}

.containerSumary .containerCenter {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.containerSumary .containerCenter .containerHeader {
  display: flex;
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  height: 60px;
  align-items: end;
  padding: 10px;
}

.containerSumary .containerCenter .containberCards {
  display: flex;
  flex: 1;
  gap: 3px;
}

.containerSumary .containerCenter .containberCards .cardItens {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex: 1;
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  height: 80px;
}

.containerSumary .containerGRaph {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 775px) {

  .containerHeaderReports {
    overflow: auto;
  }

  .containerSumary {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
  overflow: auto;
}
  
  .containerSumary .containerCards {
    display: flex;
    flex: 1;
    gap: 20px;
    flex-direction: column;
  }
  
  .containerSumary .containerCards .card {
    display: flex;
    flex: 0;
    flex-direction: column;
    background: #FAFAFA;
    box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
    border-radius: 2.5px;
    height: 210px;
    padding: 20px;
  }
  
  .containerSumary .containerCards .card .containerText {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin: 5px 0;
  }
  
  .containerSumary .subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #0E1F41;
  }
  
  .containerSumary .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #0E1F41;
    margin-bottom: 5px;
  }
  
  .containerSumary .containerCenter {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .containerSumary .containerCenter .containerHeader {
    display: flex;
    background: #FAFAFA;
    box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
    border-radius: 2.5px;
    height: 60px;
    align-items: end;
    padding: 10px;
  }
  
  .containerSumary .containerCenter .containberCards {
    display: flex;
    flex: 1;
    gap: 3px;
  }
  
  .containerSumary .containerCenter .containberCards .cardItens {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex: 1;
    background: #FAFAFA;
    box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
    border-radius: 2.5px;
    height: 100%;
  }
  
  .containerSumary .containerGRaph {
    display: flex;
    align-items: center;
    justify-content: center;
  }
} 
