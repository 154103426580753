.containerCronometro {
  display: flex;
}

.containerCronometro .containersDataDay {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 7px;
}

.containerCronometro .containersData {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerCronometro .containersDataPonto {
  font-size: 12px;
  display: flex;
  align-items: flex-end;
}


.containerCronometro .containersData .text,
.containerCronometro .containersDataDay .text{
  font-size: 8px;
  margin: 0px;
  height: 17px;
}

.containerCronometro .containersData .date,
.containerCronometro .containersDataDay .date{
  font-size: 20px;
  margin: 0px;
}

@media only screen and (max-width: 775px) {

  
  .containerCronometro .containersDataPonto {
    font-size: 10px;
    display: flex;
    align-items: flex-end;
  }
  
  
  .containerCronometro .containersData .text,
  .containerCronometro .containersDataDay .text{
    font-size: 8px !important;
    margin: 0px;
    height: 13px;
  }
  
  .containerCronometro .containersData .date,
  .containerCronometro .containersDataDay .date{
    font-size: 15px;
    margin: 0px;
  }
  
}

