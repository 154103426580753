.containerSideBar {
  /* position: absolute;
  left: 0;
  bottom: 0;
  top: 0; */
  /* width: 338px ; */
  min-height: 100vh;
}

.marginLeftSideBar {
  margin-left: 30px;
}

.selectedMenu rect {
  fill: #75CBEE;
}

.selectedMenuDash path {
  fill: #75CBEE;
}

.iconMenu {
  margin-left: 10px;
}

.iconMenuCard rect {
  fill: transparent;
}

.iconMenuCard svg {
  width: 20px;
}

.textMenu {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #FAFAFA;
}

.selectedTextMenu {
  color: #75CBEE;
  text-decoration: underline;
}

.lineDivision {
  border: 2px solid #FAFAFA;
  margin-right: 30px;
  margin-bottom: 7px;
  width: 272px;
}

.itemMenuCalendar {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #FAFAFA;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.menuSidStyle {
  margin-top: 100px;
}

@media only screen and (max-width: 775px) {
  .menuSidStyle {
    margin-top: 0px;
  }
  .containerSideBar {
    width: 140px;
  }
  .lineDivision {
    width: 90%;
    margin-right: 5px;
    margin-bottom: 4px;
  }

  .acordionCOntainer {
    width: 230px;
  }

  .textMenu {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #FAFAFA;
  }
  
  .itemMenuCalendar {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #FAFAFA;
    margin-left: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .iconeNOneDisplay {
    display: none;
  }
  
}