.containerCardAdmin {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  width: 350px;
  height: 115px;
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.containerCardAdmin .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
}

.containerCardAdmin .subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.containerAdminControl .containerButtonNewAdmin {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.containerAdminControl .containerCardAdmin {
  position: relative;
}

.containerAdminControl .containerCard .containerButtonUpdateAdmin {
  position: absolute;
  top: 10px;
  right: 6px;
  display: flex;
}

.buttonDeleteAdmin {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.buttonON {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: #0E1F41;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #FAFAFA;
}

@media only screen and (max-width: 775px) {
  body .containerCardAdmin {
    background: #FAFAFA;
    box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
    border-radius: 2.5px;
    width: 90%;
    height: 115px;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
  }
  
  body .containerCardAdmin .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #0E1F41;
  }
  
  body .containerCardAdmin .subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #0E1F41;
  }
  
  body .containerAdminControl .containerButtonNewAdmin {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  

  body .buttonON {
    border-radius: 4px;
    background-color: #0E1F41;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #FAFAFA;
  }
}