.containerModalcomplete2 {
  background: #FAFAFA;
  border-radius: 3px;
  width: 600px;
  height: 780px;
  padding: 20px;
  box-shadow: 0px 3px 10px 0px #7DA3B480;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.containerModalcomplete2 .headerComplete {
  border-bottom: 2px dotted #0E1F41;
  padding: 10px 0;
}

.containerModalcomplete2 .headerComplete .title {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
}

.containerModalcomplete2 .headerComplete .subtitle {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
}

.containerModalcomplete2 .containerButtonsComlete {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.containerModalcomplete2 .containerButtonsComlete .buttonCOmplete {
  width: 40px;
  height: 38px;
  padding: 7px 8.399999618530273px 7px 8.399999618530273px;
  border-radius: 5.599999904632568px;
  border: 0px 0.699999988079071px 0px 0px;
  gap: 5.599999904632568px;
  cursor: pointer;
  border-right: 0.7px solid #D0D5DD
}

.containerModalcomplete2 .containerButtonsComlete .colorSetComplete {
  background: linear-gradient(0deg, #0E1F41, #0E1F41);
}

.containerModalcomplete2 .containerButtonsComlete .colorFinishComplete {
  background: linear-gradient(0deg, #66CDAA, #66CDAA),linear-gradient(0deg, #D0D5DD, #D0D5DD);
}

.containerModalcomplete2 .containerButtonsComlete .colorDisableButton {
  background: linear-gradient(0deg, #C5D4DF, #C5D4DF),linear-gradient(0deg, #D0D5DD, #D0D5DD);
}

.containerModalScree .scheduleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  width: 130px;
  height: 30px;
  border-radius: 5px;
  background-color: #0E1F41;
  color: #ECF7FF;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
} 

@media only screen and (max-width: 775px) {

  .containerModalcomplete2 {
    background: #FAFAFA;
    border-radius: 3px;
    width: 80%;
    height: 75%;
    padding: 10px;
    box-shadow: 0px 3px 10px 0px #7DA3B480;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }
  
  .containerModalcomplete2 .headerComplete {
    border-bottom: 2px dotted #0E1F41;
    padding: 6px 0;
  }
  
  .containerModalcomplete2 .headerComplete .title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #0E1F41;
  }
  
  .containerModalcomplete2 .headerComplete .subtitle {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #0E1F41;
  }
  
  .containerModalcomplete2 .containerButtonsComlete {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .containerModalcomplete2 .containerButtonsComlete .buttonCOmplete {
    width: 40px;
    height: 38px;
    padding: 7px 8.399999618530273px 7px 8.399999618530273px;
    border-radius: 5.599999904632568px;
    border: 0px 0.699999988079071px 0px 0px;
    gap: 5.599999904632568px;
    cursor: pointer;
    border-right: 0.7px solid #D0D5DD
  }
}