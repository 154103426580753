.containerCardRegions {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  padding: 20px;
  max-height: 80vh;
}

.containerCardRegions .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px dashed #0E1F41;
  padding: 15px 0;
}

.containerCardRegions .header .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
}

.containerCardRegions .card {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 28px;
  line-height: 33px;
  padding: 15px 0;
  cursor: pointer;
}


.containerCardRegions .selected {
  font-weight: 400;
  color: #0E1F41;
  border-bottom: 1px solid #0E1F41;
}

.containerCardRegions .notSelected {
  font-weight: 200;
  color: #0E1F41;
  border-bottom: 1px solid #0E1F41;
  opacity: 0.5;
}

.containerSelectAdmin {
  display: flex;
}

.containerSelectAdmin .selectedCONtainer {
  background: #FAFAFA;
  border: 1px solid #0E1F41;
  border-radius: 2.5px;
  width: 100%;
  padding: 12px;
}

.containerSelectAdmin .selectedCONtainer div {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
  opacity: 0.3;
}

.containerSelectAdmin .containerIcon {
  background: #0E1F41;
  border: 1.30909px solid #0E1F41;
  border-radius: 2.5px;
  display: flex;
  /* padding: 15px; */
  width: 63px;
}

.titleINputModal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
}

.containerButtonModal {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px;
}


.containerButtonModal .cancel {
  background: #E74242;
  border-radius: 3.27273px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: right;
  color: #FAFAFA;
  border: none;
  width: 128px;
  height: 40px;
  cursor: pointer;
  text-align: center;
}

.containerButtonModal .save {
  background: #66CDAA;
  border-radius: 3.27273px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: right;
  color: #FAFAFA;
  border: none;
  width: 128px;
  height: 40px;
  cursor: pointer;
  text-align: center;
}

.containerInputText {
  width: 96%;
}

.inputText {
  background: #FAFAFA;
  border: 1px solid #0E1F41;
  border-radius: 2.5px;
  width: 100%;
  padding: 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px; 
  color: #b3b8c3;
}

@media only screen and (max-width: 775px) {
  .containerButtonModal {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 8px;
    gap: 20px;
  }
  
  
  .containerButtonModal .cancel {
    background: #E74242;
    border-radius: 2.27273px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 13px !important;
    line-height: 15px;
    text-align: right;
    color: #FAFAFA;
    border: none;
    width: 40%;
    height: 20px;
    cursor: pointer;
    text-align: center;
  }
  
  .containerButtonModal .save {
    background: #66CDAA;
    border-radius: 3.27273px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 13px !important;
    line-height: 15px;
    text-align: right;
    color: #FAFAFA;
    border: none;
    width:40%;
    height: 20px;
    cursor: pointer;
    text-align: center;
  }
  .containerInputText {
    width: 90%;
  }
  
  .inputText {
    background: #FAFAFA;
    border: 1px solid #0E1F41;
    border-radius: 1.5px;
    width: 100%;
    padding: 6px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px !important;
    line-height: 18px; 
    color: #b3b8c3;
  }

  
  .containerCardRegions .header .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #0E1F41;
  }
  
  .containerCardRegions .card {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 20px;
    line-height: 26px;
    padding: 15px 0;
    cursor: pointer;
  }
  
  
  .containerSelectAdmin .selectedCONtainer div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: #0E1F41;
    opacity: 0.3;
  }
  
  
  .titleINputModal {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px;
  }
  
 
  
  .containerButtonModal .cancel {
    background: #E74242;
    border-radius: 3.27273px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: right;
    color: #FAFAFA;
    border: none;
    width: 128px;
    height: 40px;
    cursor: pointer;
    text-align: center;
  }
  
  .containerButtonModal .save {
    background: #66CDAA;
    border-radius: 3.27273px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: right;
    color: #FAFAFA;
    border: none;
    width: 128px;
    height: 40px;
    cursor: pointer;
    text-align: center;
  }
  .containerCardRegions .header {
    gap: 30px;
  }
  
}