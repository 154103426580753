.containerPriceTable .titleTable {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #0E1F41;
}

.containerPriceTable .subtitleTable {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #0E1F41;
}

.containerHeaderTablePrice {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.containerHeaderTablePrice .left {
  display: flex;
  gap: 20px;
  align-items: center;
}

.containerHeaderTablePrice .rigth {
  display: flex;
  gap: 10px;
  align-items: center;
}

.containerHeaderTablePrice .left .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
}

.containerHeaderTablePrice .left button {
  width: 176px;
  height: 39px;
  background: #66CDAA;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #F1FCFF;
  border: none;
  cursor: pointer;
}

.containerHeaderTablePrice .rigth .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #0E1F41;
}

.containerSelectAdmin .selectedContainer {
  background: #FAFAFA;
  border: 1px solid #0E1F41;
  border-radius: 2.5px;
  width: 160px;
  padding: 12px;
}

.containerSelectAdmin .selectedContainer div {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.containerScrollTableLog {
  height: 10vh;
}

.containerTextTable {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

.containerTextTable .text {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 17px;
  line-height: 15px;
  color: #0E1F41;
}

.textTable {
  font-family: 'Roboto';
  font-style: normal;
  font-size: 17px;
  line-height: 15px;
  color: #0E1F41;
}

.containerTextTable .title {
  font-weight: 700;
  font-size: 19px;
}

.containerTextTable .before {
  padding: 10px 10px;
  background-color: rgb(230, 104, 104);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
}

.containerTextTable .after {
  padding: 10px 10px;
  background-color: rgb(65, 214, 65);
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
}

.containerRowTablew {
  display: flex;
  flex-direction: column;
}

.containerOverview .containerCard .cardInfos {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  width: 340px;
  min-height: 150px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.cardId {
  position: absolute;
  right: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  letter-spacing: 0.055em;
  color: #0E1F41;
}

.textCardCleaner {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.055em;
  color: #0E1F41;
}

.userPhoto {
  background: #FFFFFF;
  border: 4px solid #66CDAA;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.textDate {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.textCardOver {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.titleFilter {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
  margin-top: 20px;
}

.subTitleFilter {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}
.textTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #0E1F41;
  border-bottom: 2px solid #0E1F41;
  padding-bottom: 10px;
  margin-bottom: 20px;
}