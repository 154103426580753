.containerModalcomplete {
  background: #FAFAFA;
  border-radius: 3px;
  width: 600px;
  height: 700px;
  padding: 20px;
  box-shadow: 0px 3px 10px 0px #7DA3B480;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} 

.containerModalcomplete .headerComplete {
  border-bottom: 2px dotted #0E1F41;
  padding: 10px 0;
}

.containerModalcomplete .headerComplete .title {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
}

.containerModalcomplete .headerComplete .subtitle {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
}

.containerModalcomplete .containerButtonsComlete {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.containerModalcomplete .containerButtonsComlete .buttonCOmplete {
  width: 40px;
  height: 38px;
  padding: 7px 8.399999618530273px 7px 8.399999618530273px;
  border-radius: 5.599999904632568px;
  border: 0px 0.699999988079071px 0px 0px;
  gap: 5.599999904632568px;
  cursor: pointer;
  border-right: 0.7px solid #D0D5DD
}

.containerModalcomplete .containerButtonsComlete .colorSetComplete {
  background: linear-gradient(0deg, #0E1F41, #0E1F41);
}

.containerModalcomplete .containerButtonsComlete .colorFinishComplete {
  background: linear-gradient(0deg, #66CDAA, #66CDAA),linear-gradient(0deg, #D0D5DD, #D0D5DD);
}

.containerModalcomplete .containerButtonsComlete .colorDisableButton {
  background: linear-gradient(0deg, #C5D4DF, #C5D4DF),linear-gradient(0deg, #D0D5DD, #D0D5DD);
}


@media only screen and (max-width: 775px) {
  .containerModalcomplete {
    width: 80%;
    height: 80%;
  }
  .containerModalcomplete .headerComplete .title {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #0E1F41;
  }
  
  .containerModalcomplete .headerComplete .subtitle {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #0E1F41;
  }
  
}