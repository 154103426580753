.containerDetailCleaner {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}

.containerDetailCleaner .containerButtonTopHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerButtonTopHeader .buttonbACK {
  background-color: transparent;
  border: none;
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
}

.containerButtonTopHeader .buttonSend {
  background-color: #66CDAA;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
}

.containerDetailCleaner .conatainerHeader {
  display: flex;
  flex: 1;
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  padding: 10px 15px;
}

.containerDetailCleaner .conatainerHeader .containerUserHeader {
  display: flex;
  gap: 20px;
  align-items: center;
  flex: 1.5;
}

.containerDetailCleaner .conatainerHeader .containerUserHeader .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
}

.conatainerHeader .containerIcon {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  color: #0E1F41;
  font-weight: 400;
}

.conatainerHeader .button {
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #F1FCFF;
  padding: 3px 13px;
  border: none;
  cursor: pointer;
  width: 176px;
}

.containerDetailCleaner .containerInfo {
  display: flex;
  flex: 8;
  gap: 20px;
}

.containerDetailCleaner .containerInfo .containerLeft {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.containerDetailCleaner .containerInfo .card {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  padding: 20px 15px;
}

.containerDetailCleaner .containerInfo .card .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
  border-bottom: 2px dashed #0E1F41;
  padding: 5px 0;
  margin-bottom: 10px;
}

.containerDetailCleaner .containerInfo .card .titleSchedules {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  border-bottom: 2px dashed #0E1F41;
  padding: 5px 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerDetailCleaner .containerInfo .card .selectedSchedule {
  color: #0E1F41;
  cursor: pointer;
}

.containerDetailCleaner .containerInfo .card .noSelectedSchedule {
  color: #0E1F41;
  opacity: 0.5;
  cursor: pointer;
}

.containerDetailCleaner .containerInfo .card .subtile {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #0E1F41;
  line-height: 1.5;
}

.weekDayContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.weekDayContainer div {
  width: 32px;
  height: 32px;
  border: 2px solid #0E1F41;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #0E1F41;
}

.containerDetailCleaner .containerCard {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  /* justify-content: space-between; */
  gap: 10px;
  background-color: transparent;
  width: 100%;
  max-height: 400px;
  padding: 0;
  overflow: auto;
  /* height: 85vh; */
}


.containerDetailCleaner .containerCard::-webkit-scrollbar {
  display: none;
}

.containerDetailCleaner .containerCard .cardInfos {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border: 1px solid rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  width: 100%;
  min-height: 150px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
}

.containerDetailCleaner .containerCard .cardTime {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border: 1px solid rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
}

.containerDetailCleaner .containerReviews {
  height: 25vh;
  overflow: auto;
}

.buttonNOte {
  width: 160px;
  height: 34px;
  background: #66CDAA;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
}

.containerDetailCleaner .containerReviews::-webkit-scrollbar {
  width: 8px;
  background-color: #C5D4DF;
  border-radius: 5px;
}

.containerDetailCleaner .containerReviews::-webkit-scrollbar-thumb {
  background-color: #0E1F41;
  border-radius: 5px;
}

.containerDetailCleaner .containerReviews::-webkit-scrollbar-thumb:hover {
  background-color: #2C3E50;
}

.buttonDocument {
  width: 304px;
  height: 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: right;
  color: #F1FCFF;
  border: none;
  cursor: pointer;
}

.offMObileCLient {
  display: none;
}

@media only screen and (max-width: 775px) {
  .containerDetailCleaner {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
  }
  
  .containerDetailCleaner .containerButtonTopHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .containerButtonTopHeader .buttonbACK {
    background-color: transparent;
    border: none;
    display: flex;
    gap: 10px;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #0E1F41;
  }
  
  .containerButtonTopHeader .buttonSend {
    background-color: #66CDAA;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
  }
  
  .containerDetailCleaner .conatainerHeader {
    display: flex;
    flex: 1;
    background: #FAFAFA;
    box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
    border-radius: 2.5px;
    padding: 8px 12px;
  }
  
  .containerDetailCleaner .conatainerHeader .containerUserHeader {
    display: flex;
    gap: 10px;
    align-items: center;
    flex: 1.5;
  }
  
  .containerDetailCleaner .conatainerHeader .containerUserHeader .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #0E1F41;
  }
  
  .conatainerHeader .containerIcon {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    color: #0E1F41;
    font-weight: 400;
  }
  
  .conatainerHeader .button {
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #F1FCFF;
    padding: 3px 10px;
    border: none;
    cursor: pointer;
    width: 40%;
  }
  
  .containerDetailCleaner .containerInfo {
    display: flex;
    flex: 8;
    gap: 10px;
  }
  
  .containerDetailCleaner .containerInfo .containerLeft {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
  }
  
  .containerDetailCleaner .containerInfo .card {
    background: #FAFAFA;
    box-shadow: 0px 3px 8px rgba(125, 163, 180, 0.5);
    border-radius: 2.5px;
    padding: 15px 10px;
  }
  
  .containerDetailCleaner .containerInfo .card .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #0E1F41;
    border-bottom: 2px dashed #0E1F41;
    padding: 5px 0;
    margin-bottom: 10px;
  }
  
  .containerDetailCleaner .containerInfo .card .titleSchedules {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    border-bottom: 2px dashed #0E1F41;
    padding: 5px 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .containerDetailCleaner .containerInfo .card .selectedSchedule {
    color: #0E1F41;
    cursor: pointer;
  }
  
  .containerDetailCleaner .containerInfo .card .noSelectedSchedule {
    color: #0E1F41;
    opacity: 0.5;
    cursor: pointer;
  }
  
  .containerDetailCleaner .containerInfo .card .subtile {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #0E1F41;
    line-height: 1.5;
  }
  
  .weekDayContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .weekDayContainer div {
    width: 20px;
    height: 20px;
    border: 2px solid #0E1F41;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #0E1F41;
  }
  
  .containerDetailCleaner .containerCard {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    /* justify-content: space-between; */
    gap: 10px;
    background-color: transparent;
    width: 100%;
    max-height: 400px;
    padding: 0;
    overflow: auto;
    /* height: 85vh; */
  }
  
  
  .containerDetailCleaner .containerCard::-webkit-scrollbar {
    display: none;
  }
  
  .containerDetailCleaner .containerCard .cardInfos {
    background: #FAFAFA;
    box-shadow: 0px 3px 8px rgba(125, 163, 180, 0.5);
    border: 1px solid rgba(125, 163, 180, 0.5);
    border-radius: 2.5px;
    width: 100%;
    min-height: 100px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
  }
  
  .containerDetailCleaner .containerCard .cardTime {
    background: #FAFAFA;
    box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
    border: 1px solid rgba(125, 163, 180, 0.5);
    border-radius: 2.5px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
  }
  
  .containerDetailCleaner .containerReviews {
    height: 25vh;
    overflow: auto;
  }
  
  .buttonNOte {
    width: 100px;
    height: 34px;
    background: #66CDAA;
    border-radius: 5px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
  }
  
  .containerDetailCleaner .containerReviews::-webkit-scrollbar {
    width: 8px;
    background-color: #C5D4DF;
    border-radius: 5px;
  }
  
  .containerDetailCleaner .containerReviews::-webkit-scrollbar-thumb {
    background-color: #0E1F41;
    border-radius: 5px;
  }
  
  .containerDetailCleaner .containerReviews::-webkit-scrollbar-thumb:hover {
    background-color: #2C3E50;
  }
  
  .buttonDocument {
    width: 100px;
    height: 39px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 18px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: right;
    color: #F1FCFF;
    border: none;
    cursor: pointer;
  }
  .offMObileCLient {
    display: block;
  }

  .offWebCLient {
    display: none;
  }

  .flexDirecitonCOllum {
    flex-direction: column;
  }
}
