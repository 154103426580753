.containerDocuments {
  background-color: #fafafa;
  padding: 30px;
}

.containerDocuments .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
}

.containerDocuments .file {
  border-bottom: 2px dashed #0E1F41;
  padding: 15px 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #0E1F41;
  margin-bottom: 10px;
}