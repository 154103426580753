.autocomplete {
  position: relative;
  width: 100%;
}

/* Estilos do input */
.autocomplete input {
  width: 100%;
  padding: 10px;
  border: 1px solid #29272783;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Estilos da lista de sugestões */
.suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1;
  /* display: none; */
}

.autocomplete li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.autocomplete li:hover {
  background-color: #f0f0f0;
}

.autocomplete.is-open .suggestions {
  display: block;
}