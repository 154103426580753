.containerCardTemplate {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  height: 75vh;
  padding: 15px;
}

.containerCardTemplate .header {
  border-bottom: 2px dashed #0E1F41;
  padding: 10px 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.containerCardTemplate .header button {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: right;
  color: #0E1F41;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.containerCardTemplate .containerAllCards {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.containerCardTemplate .containerAllCards .card {
  border-bottom: 1px solid rgba(14, 31, 65, 0.5);
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerCardTemplate .containerAllCards .card .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
}

.containerCardTemplate .containerAllCards .card .subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 35px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #EFEFEF;
  box-shadow: inset 0px 2.66667px 3.55556px 1.33333px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0.888889px 0.444444px 2.66667px rgba(0, 0, 0, 0.25));
  border-radius: 22.2222px;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
}

.slider:before {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  background: linear-gradient(180deg, #FFFFFF 0%, #E8EAEA 100%);
  filter: drop-shadow(0.888889px 0.444444px 2.66667px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  transition: transform 0.2s;
}

input:checked+.slider {
  background: #75CBEE;
  box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);
  border-radius: 22.2222px;
}

input:checked+.slider:before {
  transform: translateX(35px);
}

@media only screen and (max-width: 775px) {

  .containerCardTemplate .header {
    border-bottom: 2px dashed #0E1F41;
    padding: 10px 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #0E1F41;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .containerCardTemplate .header button {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: right;
    color: #0E1F41;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  
  .containerCardTemplate .containerAllCards .card {
    border-bottom: 1px solid rgba(14, 31, 65, 0.5);
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .containerCardTemplate .containerAllCards .card .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #0E1F41;
  }
  
  .containerCardTemplate .containerAllCards .card .subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #0E1F41;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 15px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #EFEFEF;
    box-shadow: inset 0px 2.66667px 3.55556px 1.33333px rgba(0, 0, 0, 0.1);
    filter: drop-shadow(0.888889px 0.444444px 2.66667px rgba(0, 0, 0, 0.25));
    border-radius: 22.2222px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    background: linear-gradient(180deg, #FFFFFF 0%, #E8EAEA 100%);
    filter: drop-shadow(0.888889px 0.444444px 2.66667px rgba(0, 0, 0, 0.25));
    border-radius: 50%;
    transition: transform 0.2s;
  }
  
  input:checked+.slider {
    background: #75CBEE;
    box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);
    border-radius: 22.2222px;
  }
  
  input:checked+.slider:before {
    transform: translateX(18px);
  }
}
