.titleModalDelete {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  color: #0E1F41;
}

.containerButtonDeleteUser {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}

.containerButtonDeleteUser button {
  border-radius: 5px;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;

}

@media only screen and (max-width: 775px) {
  body .titleModalDelete {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #0E1F41;
  }
  
  body .containerButtonDeleteUser {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 15px;
  }
  
  body .containerButtonDeleteUser button {
    border-radius: 5px;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
  
  }
}