.containerGeneralReports .titleTable {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #0E1F41;
}

.containerGeneralReports .subtitleTable {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #0E1F41;
}

@media only screen and (max-width: 775px) {
  .containerGeneralReports .titleTable {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #0E1F41;
  }
  
  .containerGeneralReports .subtitleTable {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #0E1F41;
  }
} 
