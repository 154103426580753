.containerCalendarDash {
  flex: 1;
  padding:0 20px;
}

.containerCalendarDash .containerButtons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 40px;
}

.containerCalendarDash .notSelectedButtonStyle {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #0E1F41;
}

.containerCalendarDash .selectedButtonStyle {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  text-decoration-line: underline;
  color: #0E1F41;
}

.containerCalendarDash table {
  width: 100%;
  border-spacing: 0;
}

.containerCalendarDash thead {
  background-color: #0E1F41;
  flex: 1;
}

.containerCalendarDash td {
  border: 1px solid #0E1F41;
  flex: 1;
  width: calc(100%/7);
} 

.containerCalendarDash tbody tr {
  height: 20vh;
  
}

/* .containerCalendarDash .containerCardSchedules {
  height: 130px;
  overflow: auto;
}

.containerCalendarDash .containerCardSchedules::-webkit-scrollbar {
  display:none;
} */

/* .containerCalendarDash {
  position: relative;
} */

.containerCalendarDash .containerCardSchedules {
  height: 20vh;
  overflow: auto;
  margin-right: -3px;
  padding-right: 3px;
}

.containerCalendarDash .containerCardSchedules::-webkit-scrollbar {
  width: 8px;
  background-color: #C5D4DF;
  border-radius: 5px;
}

.containerCalendarDash .containerCardSchedules::-webkit-scrollbar-thumb {
  background-color: #0E1F41;
  border-radius: 5px;
}

.containerCalendarDash .containerCardSchedules::-webkit-scrollbar-thumb:hover {
  background-color: #2C3E50;
}

.containerCalendarDash th {
  flex: 1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  color: #FAFAFA;
  height: 24px;
}

.containerCalendarDash td {
  flex: 1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  height: 24px;
  padding: 5px;
}

.scheduled {
  background-color: #589FBC;
  border: 0.5px solid #0E1F41;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.done {
  background-color: #73D671;
  border: 0.5px solid #0E1F41;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.started {
  background-color: #f8fc0c;
  border: 0.5px solid #0E1F41;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.canceled {
  background-color: #EF6565;
  border: 0.5px solid #0E1F41;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.containerCalendarDash .nameCalendar {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  color: #0E1F41;
}

.containerCalendarDash .timeCalendar {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #0E1F41;
}

.containerCalendarDash .statusCalendar {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #0E1F41;
}

.containerCalendarDash .dayTextCalendar {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.05em;
  color: #0E1F41;
}
.containerCalendarDash .currentDay {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}

.containerCalendarDash .textCurrentDay {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0E1F41;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.065em;
  color: #FFFFFF;
}

@media only screen and (max-width: 775px) {

  .containerCalendarDash tbody tr {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .containerCalendarDash thead {
    display: none;
  }

  .containerCalendarDash td {
    width: 90%;
    height: 300px;
  }

  .containerCalendarDash .containerCardSchedules {
    height: 280px;
    overflow: auto;
  }


  .containerCalendarDash .notSelectedButtonStyle {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #0E1F41;
  }
  
  .containerCalendarDash .selectedButtonStyle {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: #0E1F41;
  }

  
  
  .containerCalendarDash .textCurrentDay {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0E1F41;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.065em;
    color: #FFFFFF;
  }
}