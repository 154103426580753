.containerGeneralReports {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerGeneralReports .titleHeader {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
}

.timeContainerButtons {
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
}

.containerAllValues {
  display: flex;
  gap: 20px;
}

.containerAllValues .card {
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  flex: 1;
  height: 90;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  gap: 10px;
}

.containerAllValues .card .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.containerAllValues .card .subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
}

.containerFooterGeneralReports {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.containerBestTable {
  display: flex;
  gap: 20px;
  flex: 3;
}

.containerCredtardsProperties {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #FAFAFA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border-radius: 2.5px;
  padding: 20px;
}

.containerCredtardsProperties .title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
}

.containerCredtardsProperties .subtitle {
  color: #0E1F41;
  font-size: 100px;
  font-family: Roboto;
  font-weight: 500;
}

.containerGeneralReports .containerBox {
  position: relative;
  width: 150px;
}

.containerGeneralReports .contentText {
  position: absolute;
  top: -8px;
  left: 5px;
  background-color: #e5ebf2;
  z-index: 1;
  color: #0E1F41;
  font-size: 12px;
  font-family: Roboto;
}

.containerHeaderReports {
  display: flex;
  justify-content: space-between;
}

.containerHeaderReports .reportsDoc {
  display: flex;
  gap: 20px;
}

.containerHeaderReports .reportsDoc .containerButon {
  display: flex;
  gap: 5px;
  align-items: flex-start;
}

.containerHeaderReports .reportsDoc .containerButon button {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
  border: none;
  background-color: transparent;
  color: #0E1F41;
  cursor: pointer;
}

@media only screen and (max-width: 775px) {

  .containerGeneralReports .titleHeader {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: #0E1F41;
  }
  
  .containerAllValues .card .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #0E1F41;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .containerAllValues .card .subtitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    color: #0E1F41;
  }
  
 
  .containerCredtardsProperties .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #0E1F41;
  }
  
  .containerCredtardsProperties .subtitle {
    color: #0E1F41;
    font-size: 60px;
    font-family: Roboto;
    font-weight: 500;
  }
  
  .containerHeaderReports .reportsDoc .containerButon button {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    border: none;
    background-color: transparent;
    color: #0E1F41;
    cursor: pointer;
  }

  .timeContainerButtons {
    align-items: center;
    display: flex;
    width: 100%;
  }
} 
