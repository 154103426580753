.containerButonsReportsMenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.containerButonsReportsMenu button{
  color: #0E1F41;
  text-align: center;
  font-size: 24px;
  font-family: Roboto;
  font-weight: 200;
  opacity: 0.5;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.containerButonsReportsMenu .selected {
  text-decoration-line: underline;
  opacity: 1;
  font-weight: 600;
}