.filterCALENDERtOP {
  justify-content: space-between;
}

@media only screen and (max-width: 775px) {
  body .filterCALENDERtOP {
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}
