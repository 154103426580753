.containerPaymentDash {
  display: flex;
  flex: 1;
  /* height: 100%; */
}

.cardTotalPayments1,
.cardTotalPayments2 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardTotalPayments1 {
  border-right: 1px solid #0E1F41;
  color: #2C7D2A;
}

.cardTotalPayments2 {
  color: #BD1919;
}

.subtitleCharge {
  margin-left: 25px;
  font-size: 14px;
  font-weight: 700;
  color: #94a2ac;
}

.titleCharge {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
  color: #0E1F41;
  margin-left: 25px;
  margin-top: 12px;
  font-size: 20px;
  font-weight: 700;
}

.containerCharge {
  height: 15vh;
  overflow: auto;
}

@media only screen and (max-width: 775px) {
  .cardTotalPayments1,
  .cardTotalPayments2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .subtitleCharge {
    margin-left: 25px;
    font-size: 12px;
    font-weight: 700;
    color: #94a2ac;
  }
  
  .titleCharge {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    text-align: justify;
    color: #0E1F41;
    margin-left: 25px;
    margin-top: 7px;
    font-size: 20px;
    font-weight: 700;
  }
}
