.healderNewEditForm {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  border-bottom: 2px dashed #0E1F41;
  color: #0E1F41;
  padding: 10px 0;
}

.cancelEmails {
  border-radius: 5px;
  padding: 16px 64px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px; 
  text-align: right;
  color: #F1FCFF;
  border: none;
  bottom: 30px;
  margin-top: 30px;
}

@media only screen and (max-width: 775px) {
  .cancelEmails {
    border-radius: 5px;
    padding: 5px 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px; 
    text-align: right;
    color: #F1FCFF;
    border: none;
    bottom: 30px;
    margin-top: 30px;
    text-align: center;
  }
  .healderNewEditForm {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    border-bottom: 2px dashed #0E1F41;
    color: #0E1F41;
    padding: 10px 0;
  }
}
