.containerAccountsNotifications {
  background-color: #FAFAFA;
  margin: 23px;
  padding: 10px;
}

.containerAccountsNotifications .cardNotifications {
  display: flex;
  flex: 1;
  margin: 10px 23px;
  border-bottom: 1px dotted #0E1F41;
  padding: 10px 0px;
}

.containerAccountsNotifications .cardNotifications .card {
  display: flex;
}

.containerAccountsNotifications .cardNotifications .title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
}

.containerAccountsNotifications .header {
  display: flex;
  flex: 1;
  margin: 10px 23px;
  border-bottom: 1px solid #0E1F41;
  padding: 10px 0px;
}

.containerAccountsNotifications .header .title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #0E1F41;
  display: flex;
}


@media only screen and (max-width: 775px) {
  .containerAccountsNotifications {
    background-color: #FAFAFA;
    margin: 23px;
    padding: 10px;
  }
  
  .containerAccountsNotifications .cardNotifications {
    display: flex;
    flex: 1;
    margin: 10px 23px;
    border-bottom: 1px dotted #0E1F41;
    padding: 10px 0px;
  }
  
  .containerAccountsNotifications .cardNotifications .card {
    display: flex;
  }
  
  .containerAccountsNotifications .cardNotifications .title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #0E1F41;
  }
  
  .containerAccountsNotifications .header {
    display: flex;
    flex: 1;
    margin: 10px 15px;
    border-bottom: 1px solid #0E1F41;
    padding: 10px 0px;
  }
  
  .containerAccountsNotifications .header .title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #0E1F41;
    display: flex;
  }
}