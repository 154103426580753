.chatButton {
  position: fixed;
  bottom: 0px;
  right: 100px;
  background-color: #66CDAA;
  box-shadow: 0px 3px 10px rgba(125, 163, 180, 0.5);
  border: none;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  filter: drop-shadow(0px 3px 10px rgba(125, 163, 180, 0.5));
}

.chatButton:hover {
  transform: scale(1.05);
}

.chatWindow {
  position: fixed;
  bottom: 0;
  right: 100px;
  width: 400px;
  height: 500px;
  border-radius: 2.5px 2.5px 0px 0px;
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  z-index: 9999;
}

.chatHeader {
  background-color: #0E1F41;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 2.5px 2.5px 0px 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;

  color: #FAFAFA;
}

.chatHeader img {
  margin-right: 10px;
}

.chatBody {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.chatBody::-webkit-scrollbar {
  width: 8px;
  background-color: #C5D4DF;
  border-radius: 5px;
}

.chatBody::-webkit-scrollbar-thumb {
  background-color: #0E1F41;
  border-radius: 5px;
}

.chatBody::-webkit-scrollbar-thumb:hover {
  background-color: #2C3E50;
}

.chatMessage {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: #0E1F41;
  border-radius: 20px 20px 0px 20px;
  width: 80%;
}

.admin {
  background-color: #0E1F41;
  color: #FAFAFA;
}

.closeCHat {
  border: none;
  background-color: transparent;
  color: #FAFAFA;
  font-weight: 900;
  font-size: 20px;
  cursor: pointer;
}

.cleaner {
  background-color: #E5EBF2;
  color: #0E1F41;
}

.chatSender {
  font-weight: bold;
}

.chatText {
  background-color: var(--fb-gray);
  padding: 10px;
  border-radius: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
}

.chatFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #0E1F41;
}

.chatFooter input[type="text"] {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
}

.chatFooter button {
  background-color: var(--fb-secondary);
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
