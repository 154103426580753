.containerNotificationsDashCard {
  height: 30vh;
  overflow: auto;
  margin-right: -3px;
  padding-right: 3px;
}

.containerNotificationsDashCard::-webkit-scrollbar {
  width: 8px;
  background-color: #C5D4DF;
  border-radius: 5px;
}

.containerNotificationsDashCard::-webkit-scrollbar-thumb {
  background-color: #0E1F41;
  border-radius: 5px;
}

.containerNotificationsDashCard::-webkit-scrollbar-thumb:hover {
  background-color: #2C3E50;
}

.containerButtonsNotifications {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 10px;
}

.containerButtonsNotifications .selectedButtonStyle {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #0E1F41;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.containerButtonsNotifications .notSelectedButtonStyle {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 200;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #0E1F41;
  opacity: 0.8;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.containerNotificationsDash .containerCard {
  background: #FAFAFA;
  border: 1px solid #0E1F41;
  border-radius: 2.5px;
  padding: 10px;
  margin: 10px 15px;
}

.containerNotificationsDash .containerCard .title {
  font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #0E1F41;
  }
  
  .containerNotificationsDash .containerCard .subtitle,
  .subtitleNone {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: justify;
    color: #0E1F41;
  }

  .subtitleNone {
    margin-left: 25px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
  }