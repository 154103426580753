.containerCalendarCleaner {
  flex: 1;
  padding: 0 20px;
}

.containerCalendarCleaner .containerButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerCalendarCleaner .containerButtons button {
  border: none;
  background-color: transparent;
  font-size: 26px;
  cursor: pointer;
}

.containerCalendarCleaner .containerButtons div {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: #0E1F41;
  margin: 0 5px;
}
.buttonRunCalendar {
  background: #66CDAA;
  border-radius: 5px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  width: 160px;
  height: 34px;
  cursor: pointer;
}

.containerCalendarCleaner table {
  width: 100%;
  border-spacing: 0;
}

.containerCalendarCleaner thead {
  background-color: #0E1F41;
  flex: 1;
}

.containerCalendarCleaner td {
  border: 1px solid #0E1F41;
  flex: 1;
  width: calc(100%/7);
}

.containerCalendarCleaner tbody tr {
  /* height: 125px; */
}

.containerCalendarCleaner {
  position: relative;
}

.containerCardSchedulesCleaning {
  /* height: 90px; */
  overflow: auto;
  margin-right: -3px;
  padding-right: 3px;
}

.containerCardSchedulesCleaning::-webkit-scrollbar {
  width: 8px;
  background-color: #C5D4DF;
  border-radius: 5px;
}

.containerCardSchedulesCleaning::-webkit-scrollbar-thumb {
  background-color: #0E1F41;
  border-radius: 5px;
}

.containerCardSchedulesCleaning::-webkit-scrollbar-thumb:hover {
  background-color: #2C3E50;
}


.containerCalendarCleaner .containerCardSchedulesCleaningCalendar th {
  flex: 1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  color: #FAFAFA;
  height: 24px;
}

.containerCalendarCleaner td {
  flex: 1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  height: 24px;
  padding: 5px;
}

.containerCalendarCleaner .scheduled {
  background-color: #589FBC;
  border: 0.5px solid #0E1F41;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.containerCalendarCleaner .done {
  background-color: #73D671;
  border: 0.5px solid #0E1F41;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.containerCalendarCleaner .started {
  background-color: #f8fc0c;
  border: 0.5px solid #0E1F41;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.containerCalendarCleaner .nameCalendar {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  color: #0E1F41;
}

.containerCalendarCleaner .timeCalendar {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #0E1F41;
}

.containerCalendarCleaner .statusCalendar {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #0E1F41;
}

.containerCalendarCleaner .dayTextCalendar {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.05em;
  color: #0E1F41;
}

.containerCalendarCleaner .currentDay {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.containerCalendarCleaner .textCurrentDay {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0E1F41;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.065em;
  color: #FFFFFF;
}