.containerPayments {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 24px;
}

/* .containerCalendarDash .containerCardSchedules {
  height: 130px;
} */

.containerPayments::-webkit-scrollbar {
  display:none;
}

.containerPayments .containerButtons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}

.containerPayments .containerButtons button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.containerPayments .containerButtons .selectedStyle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  text-decoration-line: underline;
  color: #0E1F41;
}

.containerPayments .containerButtons .notSelectedStyle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #0E1F41;
  opacity: 0.5;
}

@media only screen and (max-width: 775px) {

  .containerPayments {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 85vh;
    align-items: center;
    justify-content: center;
  }
  
  body .containerPayments .containerButtons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  body .containerPayments .containerButtons .selectedStyle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: #0E1F41;
  }
  
  body .containerPayments .containerButtons .notSelectedStyle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #0E1F41;
    opacity: 0.5;
  }
}