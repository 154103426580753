.containerFIlterRating {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.containerCardFIlter {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #b3b8c3;
}

.containerBOli {
  display: flex;
  align-items: center;
  justify-self: flex-start;
  /* width: 100%; */
}

.containerBOli .bolinha {
  background-color: #b3b8c3;
  width: 10px !important;
  height: 10px !important;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.selectedRating .containerBOli .bolinha{
  background-color: #0E1F41;
}

.selectedRating .title {
  color: #0E1F41;
}

.containerBOli .linha {
  background-color: #b3b8c3;
  height: 1px;
  width: 100%;
}

.selectedRating .starIcon path {
  opacity: 1
}

.selectedButtonCLeaner {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  text-decoration-line: underline;
  color: #0E1F41;
  border: none;
  background-color: transparent;
  cursor: pointer;
} 

.notSelectedButtonCLeaner {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #0E1F41;
  opacity: 0.5;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media only screen and (max-width: 775px) {
  body .selectedButtonCLeaner {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: #0E1F41;
    border: none;
    background-color: transparent;
    cursor: pointer;
  } 
  
  body .notSelectedButtonCLeaner {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 200;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #0E1F41;
    opacity: 0.5;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}
