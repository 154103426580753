.buttonCardDetails {
  background: #66CDAA;
  border-radius: 5px;
  width: 79px;
  height: 41px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #FAFAFA;
  cursor: pointer;
  border: none;
}

.containerModal {
  width: 580px;
  /* height: 586px; */
  background: #E5EBF2;
  border-radius: 2.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
}

.textCardModal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.titleModal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
}

.subTitleModal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
}

.textStatusCleaning {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #66CDAA;
}

.lineDivisionModal {
  width: 100%;
  opacity: 0.5;
  border: 0.8px solid #0E1F41;
  margin-bottom: 20px;
  margin-top: 20px;
}

.cancelButtonModal,
.completeButtonModal {
  width: 165px;
  height: 44px;
  left: 40px;
  top: 552px;
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #F1FCFF;
  border: none;
  cursor: pointer;
  text-align: center;
}

.cancelButtonModal {
  background: #E64242;
}

.completeButtonModal {
  background: #66CDAA;
}

.containerDetailsSchedule .done {
  background-color: #b3ebb3;
}

.containerDetailsSchedule .canceled {
  background-color: #c4a883;
}

.containerDetailsSchedule .scheduled {
  background-color: #b5bdda;
}

.containerDetailsSchedule .started {
  background-color: #faef8f;
}

.buttonViewChat {
  background-color: transparent;
  border: none;
  color: #0E1F41;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-size: 20px;
  text-decoration: underline;
}

@media only screen and (max-width: 775px) {
  .containerModal {
    width: 80%;
  }

  .textCardModal {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #0E1F41;
  }

  .titleModal {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #0E1F41;
  }

  .subTitleModal {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #0E1F41;
  }

  .textStatusCleaning {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: #66CDAA;
  }

  .cancelButtonModal,
  .completeButtonModal {
    width: 100%;
    height: 44px;
    left: 40px;
    top: 552px;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #F1FCFF;
    border: none;
    cursor: pointer;
    text-align: center;
  }

  .buttonViewChat {
    background-color: transparent;
    border: none;
    color: #0E1F41;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
  }

}