.containerCardRegions .containerAllCardCities {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px;
  margin: 0px;
  gap: 20px;
  max-height: 65vh;
  overflow: auto;
}

/* .containerCardRegions .containerAllCardCities::-webkit-scrollbar {
  display:none;
} */

.containerCardRegions .containerAllCardCities::-webkit-scrollbar {
  width: 8px;
  background-color: #C5D4DF;
  border-radius: 5px;
}

.containerCardRegions .containerAllCardCities::-webkit-scrollbar-thumb {
  background-color: #0E1F41;
  border-radius: 5px;
}

.containerCardRegions .containerAllCardCities::-webkit-scrollbar-thumb:hover {
  background-color: #2C3E50;
}


.containerCardRegions .containerCardCities {
  flex: 1;
}

.containerCardRegions .containerAllCardCities .line {
  flex: 0.01;
  opacity: 0.25;
  border-right: 1px dashed #0E1F41;
  margin: 20px 20px;
}

.containerCardRegions .containerAllCardCities .line-container {
  flex: 1;
  display: flex;
  height: 100%;
  /* flex-direction: column; */
}