.containerMenuHeaderTampletas {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerMenuHeaderTampletas .buttonbACK {
  background-color: transparent;
  border: none;
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #0E1F41;
}

@media only screen and (max-width: 775px) {
  .containerMenuHeaderTampletas .buttonbACK {
    background-color: transparent;
    border: none;
    display: flex;
    gap: 10px;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #0E1F41;
  }
}
