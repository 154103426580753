.containerRegions {
  display: flex;
  gap: 20px;
}

@media only screen and (max-width: 775px) {
  .containerRegions {
    flex-direction: column;
    height: 100%;
  }
} 