.subtitleCoupon {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #0E1F41;
}

.itemUsed {
  width: 100%;
  padding: 0px 10px;
  height: 32px;
  border-radius: 4px;
  background-color: #0E1F41;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #fafafa;
  margin-right: 10px;
}

@media only screen and (max-width: 775px) {
  body .subtitleCoupon {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;;
    line-height: 20px;
    color: #0E1F41;
  }
  
  body .itemUsed {
    width: 100%;
    padding: 0px 10px;
    height: 32px;
    border-radius: 4px;
    background-color: #0E1F41;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #fafafa;
    margin-right: 10px;
  }

  body .title {
    font-size: 18px !important;;
  }
}
