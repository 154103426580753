.containerCalendar {
  flex: 1;
  padding: 0 20px;
}

.containerCalendar .containerButtons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerCalendar .containerButtons button {
  border: none;
  background-color: transparent;
  font-size: 26px;
  cursor: pointer;
}

.containerCalendar .containerButtons div {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #0E1F41;
  margin: 0 5px;
}

.buttonRunCalendar {
  background: #66CDAA;
  border-radius: 5px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  width: 160px;
  height: 34px;
  cursor: pointer;
}

.containerCalendar table {
  width: 100%;
  border-spacing: 0;
}

.containerCalendar thead {
  background-color: #0E1F41;
  flex: 1;
}

.containerCalendar td {
  border: 1px solid #0E1F41;
  flex: 1;
  width: calc(100%/7);
}

.containerCalendar tbody tr {
  height: calc(70vh/6);
}

.containerCalendar {
  position: relative;
}

.containerCardSchedules {
  height: calc(68vh/6);
  overflow: auto;
  margin-right: -3px;
  padding-right: 3px;
}

.containerCardSchedules::-webkit-scrollbar {
  width: 8px;
  background-color: #C5D4DF;
  border-radius: 5px;
}

.containerCardSchedules::-webkit-scrollbar-thumb {
  background-color: #0E1F41;
  border-radius: 5px;
}

.containerCardSchedules::-webkit-scrollbar-thumb:hover {
  background-color: #2C3E50;
}


.containerCalendar .containerCardSchedulesCalendar th {
  flex: 1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  color: #FAFAFA;
  height: 24px;
}

.containerCalendar td {
  flex: 1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  height: 24px;
  padding: 5px;
}

.containerCalendar .scheduled {
  background-color: #589FBC;
  border: 0.5px solid #0E1F41;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.containerCalendar .done {
  background-color: #73D671;
  border: 0.5px solid #0E1F41;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.containerCalendar .started {
  background-color: #f8fc0c;
  border: 0.5px solid #0E1F41;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.containerCalendar .nameCalendar {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  color: #0E1F41;
}

.containerCalendar .timeCalendar {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #0E1F41;
}

.containerCalendar .statusCalendar {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #0E1F41;
}

.containerCalendar .dayTextCalendar {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.05em;
  color: #0E1F41;
}

.containerCalendar .currentDay {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.containerCalendar .textCurrentDay {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0E1F41;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.065em;
  color: #FFFFFF;
}

@media only screen and (max-width: 775px) {
  .containerCalendar tbody tr {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .containerCalendar thead {
    display: none;
  }

  .containerCardSchedules {
    height: 90%;
  }

  .containerCalendar td {
    width: 90%;
    height: 300px;
  }

  .containerTableCalendar {
    overflow-x: hidden;
  }

  .cardDayConainer {
    /* background-color: #2C3E50; */
  }
}